import { normalize, schema } from "normalizr";
import deepmerge from "deepmerge";
import {
  NormalizedSection,
  Section,
  Entities,
  ColorScheme,
} from "@/types/index";
import { AnyAction } from "@/store/index";

const renderer = new schema.Entity("renderers");

const subsection = new schema.Entity("subsections", {
  renderers: new schema.Array(renderer),
});
const section = new schema.Entity("sections");
section.define({
  renderers: new schema.Array(renderer),
  subsections: new schema.Array(subsection),
});

export const schemas = {
  renderer,
  section,
};

export const mergeEntities = (entities: Entities) => {
  return { type: "entities/merge", entities };
};

export const updateEntity = (entity: any, scheme: any) => {
  return (dispatch: any, getState: any) => {
    const entities = {
      [scheme]: {
        [entity.id]: entity,
      },
    };
    dispatch({ type: "entities/merge", entities });
  };
};

export const setColorScheme = (color: ColorScheme) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: "colorScheme/set", colorScheme: color });
  };
};

export const setVersionId = (id: string) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: "versionId/set", versionId: id });
  };
};

export const setSections = (sections: Section[]) => {
  return (dispatch: any, getState: any) => {
    const normalized = normalize(sections, [schemas.section]);
    dispatch(mergeEntities(normalized.entities));
    return normalized;
  };
};

export const setSectionIds = (sectionIds: number[]) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: "sectionIds/set", sectionIds });
  };
};

export const sectionIsSame = (
  prev?: NormalizedSection,
  next?: NormalizedSection
) => {
  if (!prev || !next) return false;
  return (
    prev.id === next.id &&
    prev.layout === next.layout &&
    prev.properties?.background_image_url ===
      next.properties?.background_image_url &&
    prev.renderers?.join?.() === next.renderers?.join?.() &&
    prev.subsections?.join?.() === next.subsections?.join?.()
  );
};

type maybeNumbers = number[] | undefined;
export const sameIds = (prev: maybeNumbers, next: maybeNumbers) => {
  if (!prev || !next) return false;
  if (prev.length !== next.length) return false;
  return prev.every((id, i) => id === next[i]);
};

type State = {
  sections: {
    [id: number]: NormalizedSection;
  };
  subsections: {
    [id: number]: NormalizedSection;
  };
  renderers: {
    [id: number]: any;
  };
  sectionIds: number[];
  colorScheme: ColorScheme | null;
  versionId: string | null;
};

const initialState: State = {
  sections: {},
  subsections: {},
  renderers: {},
  sectionIds: [],
  colorScheme: null,
  versionId: null,
};

const overwriteArray = (destinationArray: any[], sourceArray: any[]) =>
  sourceArray;
const mergeOptions = {
  arrayMerge: overwriteArray,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "entities/merge": {
      const merged = deepmerge(state, action.entities, mergeOptions);
      return merged;
    }

    case "sectionIds/set": {
      const { sectionIds } = action;
      return { ...state, sectionIds };
    }

    case "colorScheme/set": {
      const { colorScheme } = action;
      return { ...state, colorScheme };
    }

    case "versionId/set": {
      const { versionId } = action;
      return { ...state, versionId };
    }

    default: {
      return state;
    }
  }
};

export { normalize } from "normalizr";
