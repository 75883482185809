import { AppDispatch, GetState } from "@/store";
import { setToast } from "@/features/toasts";
import i18n from "@/i18n";

type SupportedExtraHeaders = {
  "accept-language"?: string;
  "x-no-language-fallback"?: string; // any string will disable
};

// params is not supported yet
export const get = (
  endpoint: string,
  params?: null,
  headers?: SupportedExtraHeaders
) => {
  return async (
    dispatch: AppDispatch,
    getState: GetState
  ): Promise<Response> => {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "accept-language": i18n.language,
        ...headers,
      },
    });
    return response;
  };
};

export const patch = (
  endpoint: string,
  payload: object,
  options: Options = {}
) => {
  return async (
    dispatch: AppDispatch,
    getState: GetState
  ): Promise<Response> => {
    const response = await fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!options.noToast) {
      if (response.ok) {
        setToast({ message: i18n.t("toast.saved"), type: "success" })(dispatch);
      } else {
        setToast({ message: i18n.t("toast.error"), type: "error" })(dispatch);
      }
    }

    return response;
  };
};

type Options = {
  noToast?: boolean;
};
export const post = (
  endpoint: string,
  payload: object = {},
  options: Options = {}
) => {
  return async (
    dispatch: AppDispatch,
    getState: GetState
  ): Promise<Response> => {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!options.noToast) {
      if (response.ok) {
        setToast({ message: i18n.t("toast.saved"), type: "success" })(dispatch);
      } else {
        setToast({ message: i18n.t("toast.error"), type: "error" })(dispatch);
      }
    }

    return response;
  };
};

export const withExponentialBackoff = async <T>(
  fn: () => Promise<T>,
  maxRetries: number
): Promise<T> => {
  let retryCount = 0;
  while (retryCount < maxRetries) {
    try {
      const result = await fn();
      return result;
    } catch (error) {
      const backoffTime = calculateBackoffTime(retryCount);
      await sleep(backoffTime);
      retryCount++;
    }
  }
  throw new Error("Exceeded maximum retry attempts");
};

function calculateBackoffTime(retryCount: number) {
  const baseTimeMs = 1000; // The base time to start the backoff (in milliseconds)
  const maxBackoffTimeMs = 30000; // The maximum backoff time (in milliseconds)
  const backoffTime = Math.min(
    Math.pow(2, retryCount) * baseTimeMs,
    maxBackoffTimeMs
  );
  return backoffTime;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
