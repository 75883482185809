import React from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "@/components/ToggleButton";
import StyledToggleButtonGroup from "@/components/StyledToggleButtonGroup";

interface LanguageSwitcherProps {}

type Language = "en" | "ja";

const languages: Record<Language, { nativeName: string }> = {
  en: { nativeName: "English" },
  ja: { nativeName: "日本語" },
};

const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: Language | null
  ) => {
    if (newLanguage) {
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <StyledToggleButtonGroup
      color="primary"
      value={currentLanguage}
      exclusive
      onChange={handleLanguageChange}
      aria-label="language switcher"
    >
      {Object.entries(languages).map(([lng, { nativeName }]) => (
        <ToggleButton
          key={lng}
          value={lng}
          aria-label={nativeName}
          style={{
            fontWeight: currentLanguage === lng ? "bold" : "normal",
          }}
        >
          {nativeName}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default LanguageSwitcher;
