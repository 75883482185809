"use client";
import Text from "@/components/sections/Text";
import Box from "@/components/Box";
import useBreakpoint from "@/hooks/useBreakpoint";
import Container from "@/components/Container";
import Link from "@/components/Link";
import { useTranslation } from "@/i18n";
import Grid from "@/components/Grid";
import Image from "@/components/Image";
import { usePathname } from 'next/navigation';


const styles = {
  text: {
    color: "primary.contrastText",
  },
  link: {
    textDecoration: "underline",
    fontWeight: "400",
  },
  icon: {
    width: 48,
  },
};

const NavText = ({ children, sx }: { children: any; sx?: any }) => {
  const isMobile = useBreakpoint("md");

  return (
    <Text
      fontSize={isMobile ? "1rem" : "1.25rem"}
      sx={[styles.text, styles.link, sx]}
    >
      {children}
    </Text>
  );
};

const ExternalLink = ({ children, href }: { children: any; href: string }) => {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
};

const Footer = () => {
  const isMobile = useBreakpoint("md");
  const { t } = useTranslation();
  const pathname = usePathname();
  const isTokenPath = pathname == "/token";

  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        marginTop: "auto",
      }}
    >
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            pt: isMobile ? 2 : 4,
            pb: isMobile ? 2 : 4,
          }}
        >
          {/* Left */}
          <Grid item xs={12} md={4}>
            <Text
              variant={isMobile ? "h3" : "h2"}
              fontWeight="bold"
              sx={styles.text}
            >
              {"Open Town"}
            </Text>

            <Box sx={{ display: "flex", mt: 2 }}>
              <Text
                fontSize={isMobile ? "1rem" : "1.25rem"}
                sx={styles.text}
                mr={1}
              >
                Managed by
              </Text>
              <ExternalLink href="https://kiheitai.co.jp/">
                <NavText>
                  {isTokenPath ? "KiHeiTai Estonia" : "KiHeiTai"}
                </NavText>
              </ExternalLink>
            </Box>
          </Grid>

          {/* Center */}
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Link href="https://docs.open-town.org/whitepaper/">
                <NavText>{t("footer.whitepaper")}</NavText>
              </Link>
            </Box>

            <Link href="https://docs.open-town.org/tokuho/">
              <NavText>{t("footer.seller")}</NavText>
            </Link>
          </Grid>

          {/* Right */}
          <Grid item xs={12} md={2} sx={{ display: "flex" }}>
            <Box>
              <ExternalLink href="https://twitter.com/OpenTownProject">
                <Image
                  src="/assets/icons/twitter.png"
                  style={styles.icon}
                  alt="twitter"
                />
              </ExternalLink>
            </Box>
            <Box ml={2}>
              <ExternalLink href="https://discord.gg/kU52fBCd">
                <Image
                  src="/assets/icons/discord.png"
                  style={styles.icon}
                  alt="discord"
                />
              </ExternalLink>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Footer;
