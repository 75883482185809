import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useSelector, RootState, useDispatch } from "@/store";
import { setToast } from "@/features/toasts";
import { useState, useEffect } from "react";

const Toast = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state: RootState) => state.toasts.toast);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(!!toast?.message);
  }, [toast?.message]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setToast({ message: "", type: "success" })(dispatch);
    }, 200);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={toast?.type}
        sx={{ width: "100%" }}
      >
        {toast?.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
