import Box from "@/components/Box";
import Text from "@/components/sections/Text";
import useBreakpoint from "@/hooks/useBreakpoint";
import { colors } from "@/styles/color";
import { SxProps } from "@mui/system";
import Link from "@/components/Link";

export const headerHeight = 80;
interface HeaderProps {
  right?: React.ReactNode;
  sx?: SxProps;
  reversedStyle?: boolean;
}
const Header = ({ right, sx, reversedStyle }: HeaderProps) => {
  const isMobile = useBreakpoint("md");
  const mobileHeaderHeight = 64;

  return (
    <Box
      sx={{
        height: isMobile ? mobileHeaderHeight : headerHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        ...sx,
      }}
    >
      <Link href="https://open-town.org/" rel="noopener noreferrer">
        <Text
          variant={isMobile ? "h2" : "h1"}
          fontWeight="bold"
          sx={{ pl: 2, pt: 1 }}
          color={reversedStyle ? colors.white : colors.primary}
        >
          OpenTown
        </Text>
      </Link>
      {right}
    </Box>
  );
};

export default Header;
