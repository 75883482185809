import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";
import logger from "redux-logger";
import { useStore as useReduxStore } from "react-redux";
import storage from "redux-persist/lib/storage";

import { createWrapper } from "next-redux-wrapper";

import { reducer as entities } from "@/features/entities";
import { reducer as drafts } from "@/features/drafts";
import { reducer as v2edit } from "@/features/v2edit";
import { reducer as toasts } from "@/features/toasts";

const appReducer = combineReducers({
  entities,
  drafts,
  v2edit,
  toasts,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["entities", "v2edit"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware: any[] = [];
const isNode = typeof window === "undefined";
if (process.env.NODE_ENV === "development" && !isNode) {
  middleware = [logger];
}

// wrapper is used to allow SSR rendering on nextjs
const makeStore = () =>
  createStore(persistedReducer, applyMiddleware(...middleware));
export const wrapper = createWrapper(makeStore);

// this store is used on tests
export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);

export { useDispatch, useSelector } from "react-redux";

export const useStore = () => {
  const { dispatch, getState } = useReduxStore();
  return {
    dispatch: dispatch as AppDispatch,
    getState: getState as GetState,
  };
};

export type { AnyAction } from "redux";

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type GetState = () => RootState;
