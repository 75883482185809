import { Town, Org, Project, LanguageCode } from "@/types/api";
import { AppDispatch, GetState } from "@/store";

// v2edit state keeps structure of town returned from api
type State = {
  town?: Town;
  currentLanguage: LanguageCode | null;
};

export const setCurrentLanguage = (payload: State["currentLanguage"]) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: "edit/setCurrentLanguage", payload });
  };
};

export const setTown = (payload: Town) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: "edit/setTown", payload });
  };
};

export const setOrg = (payload: Org) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: "edit/setOrg", payload });
  };
};

export const setTownFeatures = (payload: Town["features"]) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: "edit/setTownFeatures", payload });
  };
};

export const setProjects = (payload: Town["projects"]) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: "edit/setProjects", payload });
  };
};

export const setNFTs = (payload: Project["nfts"]) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: "edit/setNFTs", payload });
  };
};

export const setTeamMembers = (
  projectId: Project["id"],
  payload: Project["team_members"]
) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: "edit/setTeamMembers", payload, projectId });
  };
};

export const setCallToAction = (
  projectId: Project["id"],
  payload: Project["cta"]
) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: "edit/setCallToAction", payload, projectId });
  };
};

type SetCurrentLanguageAction = {
  type: "edit/setCurrentLanguage";
  payload: State["currentLanguage"];
};
type SetTownAction = {
  type: "edit/setTown";
  payload: Town;
};
type SetOrgAction = {
  type: "edit/setOrg";
  payload: Org;
};
type SetTownFeaturesAction = {
  type: "edit/setTownFeatures";
  payload: Town["features"];
};
type SetProjectsAction = {
  type: "edit/setProjects";
  payload: Town["projects"];
};
type SetNFTsAction = {
  type: "edit/setNFTs";
  payload: Project["nfts"];
};
type SetTeamMembersAction = {
  type: "edit/setTeamMembers";
  payload: Project["team_members"];
  projectId: Project["id"];
};
type SetCallToActionAction = {
  type: "edit/setCallToAction";
  payload: Project["cta"];
  projectId: Project["id"];
};

type Action =
  | SetCurrentLanguageAction
  | SetTownAction
  | SetOrgAction
  | SetTownFeaturesAction
  | SetProjectsAction
  | SetNFTsAction
  | SetTeamMembersAction
  | SetCallToActionAction;

const initialState: State = {
  currentLanguage: null,
};

export const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "edit/setCurrentLanguage": {
      const { payload } = action;
      return {
        ...state,
        currentLanguage: payload,
      };
    }

    case "edit/setTown": {
      const { payload } = action;
      return {
        ...state,
        town: payload,
      };
    }

    case "edit/setOrg": {
      const { payload } = action;
      return {
        ...state,
        town: {
          ...(state.town as Town),
          organization: payload,
        },
      };
    }

    case "edit/setTownFeatures": {
      const { payload } = action;
      return {
        ...state,
        town: {
          ...(state.town as Town),
          features: payload,
        },
      };
    }

    case "edit/setProjects": {
      const { payload } = action;

      // only support one project for now
      const firstProject = payload?.[0] || {};

      return {
        ...state,
        town: {
          ...(state.town as Town),
          projects: [
            {
              ...(state.town as Town).projects?.[0],
              ...firstProject,
            },
          ] as Town["projects"],
        },
      };
    }

    case "edit/setNFTs": {
      const { payload } = action;
      return {
        ...state,
        town: {
          ...(state.town as Town),
          projects: (state.town as Town).projects?.map((project) => {
            if (project.id === payload[0].project_id) {
              return {
                ...(project as Project),
                nfts: payload,
              };
            }
            return project;
          }),
        },
      };
    }

    case "edit/setTeamMembers": {
      const { payload, projectId } = action;

      // team members should be merged, not overwritten
      const current =
        (state.town as Town).projects?.find(
          (project) => project.id === projectId
        )?.team_members || [];
      const merged = [...current, ...payload];
      const filtered = merged.filter((member, index, self) => {
        return index === self.findIndex((m) => m.id === member.id);
      });

      return {
        ...state,
        town: {
          ...(state.town as Town),
          projects: (state.town as Town).projects?.map((project) => {
            if (project.id === projectId) {
              return {
                ...(project as Project),
                team_members: filtered,
              };
            }
            return project;
          }),
        },
      };
    }

    case "edit/setCallToAction": {
      const { payload, projectId } = action;
      return {
        ...state,
        town: {
          ...(state.town as Town),
          projects: (state.town as Town).projects?.map((project) => {
            if (project.id === projectId) {
              return {
                ...(project as Project),
                cta: payload,
              };
            }
            return project;
          }),
        },
      };
    }

    default:
      return state;
  }
};
