import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type size = "xs" | "sm" | "md" | "lg" | "xl";
const useBreakpoint = (size: size) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(size));

  return match;
};

export default useBreakpoint;
