import Computer from "@mui/icons-material/Computer";
import Smartphone from "@mui/icons-material/Smartphone";
import Palette from "@mui/icons-material/Palette";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import EmailIcon from "@mui/icons-material/Email";
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CreditCard from "@mui/icons-material/CreditCard";
import InsertLink from "@mui/icons-material/InsertLink";
import Twitter from "@mui/icons-material/Twitter";
import ErrorIcon from "@mui/icons-material/Error";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Instagram from "@mui/icons-material/Instagram";
import RedeemIcon from "@mui/icons-material/Redeem";

export const map = {
  computer: Computer,
  smartphone: Smartphone,
  palette: Palette,
  addPhotoAlternateOutlined: AddPhotoAlternateOutlinedIcon,
  email: EmailIcon,
  menu: Menu,
  close: Close,
  checkCircle: CheckCircleOutline,
  creditCard: CreditCard,
  insertLink: InsertLink,
  twitter: Twitter,
  error: ErrorIcon,
  accountCircle: AccountCircle,
  logout: Logout,
  keyboardArrowRightIcon: KeyboardArrowRightIcon,
  keyboardArrowDownIcon: KeyboardArrowDownIcon,
  instagram: Instagram,
  RedeemIcon: RedeemIcon,
} as const;

const Icon = ({ name, sx, ...rest }: { name: keyof typeof map; sx?: any }) => {
  const Component = map[name];

  return <Component sx={sx} {...rest} />;
};

export default Icon;
