import MuiGrid from "@mui/material/Grid";
import { previewModeAtom, useAtom } from "@/atoms/index";

const Grid = (props: any) => {
  const [previewMode] = useAtom(previewModeAtom);

  let { xs, md, lg, ...rest } = props;

  if (previewMode === "mobile") {
    md = xs;
    lg = xs;
  }

  return <MuiGrid xs={xs} md={md} lg={lg} {...rest} />;
};

export default Grid;
