import "@/styles/global.css";
import type { AppProps } from "next/app";

import { ThemeProvider, createTheme, baseTheme } from "@/hooks/useTheme";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { wrapper } from "@/store/index";
import { SessionProvider } from "next-auth/react";
import ConstantsProvider from "../components/ConstantsProvider";
import i18n, { I18nextProvider } from "@/i18n";
import Toast from "@/components/layout/Toast";
import DefaultLayout from "@/components/layout/DefaultLayout";
import type { NextPage, InferGetServerSidePropsType } from "next";
import type { ReactElement, ReactNode } from "react";

// Required for custom layouts
// export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
//   getLayout?: (page: ReactElement) => ReactNode;
// };
// type AppPropsWithLayout = AppProps & {
//   Component: NextPageWithLayout;
// };
export type NextPageWithLayout<Props extends (args: any) => any> = NextPage<
  InferGetServerSidePropsType<Props>
> & {
  getLayout?: (
    page: React.ReactElement,
    props: InferGetServerSidePropsType<Props>
  ) => React.ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout<any>;
};

const App = ({ Component, ...rest }: AppPropsWithLayout) => {
  const theme = createTheme(baseTheme);

  const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || "";
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, [GTM_ID]);

  useEffect(() => {
    i18n.changeLanguage(i18n.resolvedLanguage);
  }, []);

  const getLayout =
    Component.getLayout ||
    ((page: ReactElement) => <DefaultLayout>{page}</DefaultLayout>);

  return (
    <Provider store={wrapper.useWrappedStore(rest).store}>
      <SessionProvider session={rest.pageProps.session}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <ConstantsProvider>
              {getLayout(<Component {...rest.pageProps} />, rest.pageProps)}
              <Toast />
            </ConstantsProvider>
          </ThemeProvider>
        </I18nextProvider>
      </SessionProvider>
    </Provider>
  );
};

export default App;
