import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./public/locales/en.json";
import ja from "./public/locales/ja.json";

const isDev = process.env.NODE_ENV === "development";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["navigator", "localStorage"],
      // disable cache in dev for easier testing, use Browser's sensors for override
      ...(isDev && { caches: [] }),
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      ja: {
        translation: ja,
      },
    },
  });

export { I18nextProvider, useTranslation, Trans } from "react-i18next";

export default i18n;
