import { useState } from "react";
import Image from "@/components/Image";
import Icon from "@/components/Icon";
import Typography from "@/components/Typography";
import Box from "@/components/Box";
import useSession from "@/hooks/useSession";
import useRouter from "@/hooks/useRouter";
import { signOut } from "next-auth/react";
import { useTranslation } from "@/i18n";
import Header from "@/components/layout/Header";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import MenuIcon from "@mui/icons-material/Menu";
import { colors } from "@/styles/color";

const CurrentUser = () => {
  const { data } = useSession();

  if (!data?.user) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {data.user.image ? (
        <Image
          src={data.user.image || ""}
          alt={data.user.name || ""}
          width={40}
          height={40}
          style={{ borderRadius: "50%" }}
        />
      ) : (
        <Icon name="accountCircle" sx={{ fontSize: 40, color: "grey.600" }} />
      )}
      <Typography variant="body2" sx={{ ml: 1, maxWidth: "90px" }}>
        {data?.user?.name}
      </Typography>
    </Box>
  );
};

const ListText = ({ label }: { label: string }) => {
  return (
    <ListItemText
      primary={label}
      primaryTypographyProps={{ sx: { fontSize: "16px" } }}
    />
  );
};

const MenuLink = ({ route, label }: { route: string; label: string }) => {
  const { push } = useRouter();

  return (
    <ListItem>
      <ListItemButton onClick={() => push(route)}>
        <ListText label={label} />
      </ListItemButton>
    </ListItem>
  );
};

const MenuHeader = () => {
  const { push } = useRouter();
  const { t } = useTranslation();
  const { status } = useSession();
  const isLoggedIn = status == "authenticated";

  const [open, setOpen] = useState(false);

  const logout = () => {
    signOut({ redirect: false });
    push("/");
  };

  return (
    <Box>
      <Header
        right={
          <Box>
            <ListItemButton onClick={() => setOpen(true)}>
              {isLoggedIn ? <CurrentUser /> : <MenuIcon color="action" />}
            </ListItemButton>
          </Box>
        }
        sx={{ pl: 2 }}
      />

      <Drawer anchor="right" open={open}>
        <Box
          sx={{
            width: 250,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          role="presentation"
          onClick={() => setOpen(false)}
        >
          <List>
            <ListItem
              secondaryAction={
                <IconButton onClick={() => setOpen(false)}>
                  <Icon name="close" />
                </IconButton>
              }
            >
              {isLoggedIn ? <CurrentUser /> : <Box sx={{ height: 40 }} />}
            </ListItem>

            <MenuLink route="/nfts/collection" label={t("menu.collection")} />
            <MenuLink route="/discover" label={t("navBar.explor")} />
            <MenuLink route="/" label={t("navBar.create")} />
          </List>

          <List>
            {isLoggedIn && (
              <ListItem>
                <ListItemButton
                  sx={{ justifyContent: "center" }}
                  onClick={logout}
                >
                  <IconButton onClick={logout}>
                    <Icon name="logout" />
                  </IconButton>
                  <ListText label={t("menu.logout")} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem sx={{ justifyContent: "center" }}>
              <LanguageSwitcher />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MenuHeader;
