import React, { ReactNode } from "react";
import { useSelector } from "@/store";
import ConstantsContext, { Constants } from "../configs/constants";
import { selectCurrentLanguage } from "@/features/v2edit/selectors";

interface ConstantsProviderProps {
  children: ReactNode;
}

const ConstantsProvider: React.FC<ConstantsProviderProps> = ({ children }) => {
  const languageCode = useSelector(selectCurrentLanguage);
  const charLengthMult = languageCode == "ja" ? 1 : 1.5;

  const constants: Constants = {
    CHAR_LENGTH_MULT: charLengthMult,
  };

  return (
    <ConstantsContext.Provider value={constants}>
      {children}
    </ConstantsContext.Provider>
  );
};

export default ConstantsProvider;
