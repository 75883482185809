import Box from "@/components/Box";
import Container from "@/components/Container";
import MenuHeader from "@/components/layout/MenuHeader";
import Footer from "../Footer";
import { ReactNode } from "react";

export default function DefaultLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "primary.light",
        color: "black",
      }}
    >
      <MenuHeader />
      <Container maxWidth="md" sx={{ pb: 2, marginBottom: "auto" }}>
        {children}
      </Container>
      <Footer />
    </Box>
  );
}
