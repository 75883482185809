import React from "react";

export interface Constants {
  CHAR_LENGTH_MULT: number;
}

const ConstantsContext = React.createContext<Constants>({
  CHAR_LENGTH_MULT: 1,
});

export default ConstantsContext;
