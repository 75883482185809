//import NextImage from "next/image";

type ImageProps = {
  src?: string;
  width?: number;
  height?: number;
  rest?: any;
  alt?: string;
  style?: any;
};

const Image = ({
  src, // image url
  width,
  height, // show placeholder image if provided, ignored if src or assetPath is given
  alt,
  style,
  ...rest
}: ImageProps) => {
  let image = src ? src : `https://placehold.co/${width}x${height}`;

  return (
    <img
      src={image}
      width={width}
      height={height}
      alt={alt}
      style={style}
      {...rest}
    />
  );
};

export default Image;
