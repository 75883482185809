const host = `${process.env.NEXT_PUBLIC_FRONTEND_API_HOST}/api/core`;
const explicitHost = `${process.env.NEXT_PUBLIC_FRONTEND_API_HOST}/api`;
//const host = `${process.env.NEXT_PUBLIC_FRONTEND_API_HOST}`;
import {
  Town,
  Org,
  OrgFeature,
  TownFeature,
  TownCommunity,
  Project,
  ProjectDetail,
  ProjectUseOfFunds,
  ProjectUseOfFundsItem,
  NFT,
  NFTOrder,
  NFTPurchase,
  Reward,
  ProjectMember,
  ArtistWork,
  CallToAction,
} from "@/types/api";
import { Address } from "@/types/contracts";

const api = {
  // editor v1 and v2 related
  image: {
    // FIXME: accept function call to be consistent with other endpoints
    upload: `${host}/media/upload_file`,
  },
  towns: {
    get: (slug: string) => `${host}/websites/town/${slug}`,
    read: (slug: string) => `${host}/towns/${slug}`,
    update: (id: number) => `${host}/towns/${id}`,
    create: () => `${host}/towns`,
  },
  organizations: {
    create: () => `${host}/organizations`,
    update: (id: number) => `${host}/organizations/${id}`,
  },
  orgFeatures: {
    create: (orgId: Org["id"]) => `${host}/organizations/${orgId}/features`,
    update: (id: OrgFeature["id"]) => `${host}/organizations/features/${id}`,
  },
  townFeatures: {
    create: (townId: Town["id"]) => `${host}/towns/${townId}/features`,
    update: (id: TownFeature["id"]) => `${host}/towns/features/${id}`,
  },
  goals: {
    create: (townId: Town["id"]) => `${host}/towns/${townId}/goals`,
    update: (id: TownFeature["id"]) => `${host}/towns/goals/${id}`,
  },
  community: {
    create: (townId: Town["id"]) => `${host}/towns/${townId}/community`,
    update: (id: TownCommunity["id"]) => `${host}/towns/community/${id}`,
  },
  projects: {
    create: () => `${host}/projects`,
    update: (id: Project["id"]) => `${host}/projects/${id}`,
  },
  projectDetails: {
    create: (projectId: Project["id"]) =>
      `${host}/projects/${projectId}/details`,
    update: (id: ProjectDetail["id"]) => `${host}/projects/details/${id}`,
  },
  projectUseOfFunds: {
    create: (projectId: Project["id"]) =>
      `${host}/projects/${projectId}/use_of_funds`,
    update: (id: ProjectUseOfFunds["id"]) =>
      `${host}/projects/use_of_funds/${id}`,
  },
  projectUseOfFundsItems: {
    create: (projectUseOfFundsId: ProjectUseOfFunds["id"]) =>
      `${host}/projects/use_of_funds/${projectUseOfFundsId}/items`,
    update: (id: ProjectUseOfFundsItem["id"]) =>
      `${host}/projects/use_of_funds/items/${id}`,
  },
  nfts: {
    create: () => `${host}/nft_projects`,
    update: (id: NFT["id"]) => `${host}/nft_projects/${id}`,
    read: (id: NFT["slug"]) => `${host}/nfts/${id}`,
  },
  nftCatalog: {
    nfts: {
      metadata: (guid: string, tokenId: number) =>
        `${explicitHost}/nft_catalog/nfts/${guid}/metadata/${tokenId}`,
    },
  },
  nftWallet: {
    assets: {
      findByContractAddressAndTokenId: (
        contractAddress: string,
        tokenId: number
      ) =>
        `${explicitHost}/nft_wallet/assets/find?contractAddress=${contractAddress}&tokenId=${tokenId}`,
      addOffchain: () => `${explicitHost}/nft_wallet/assets/add_offchain`,
    },
    transfer: (guid: string) =>
      `${explicitHost}/nft_wallet/assets/${guid}/transfer`,
    mintTransfer: (guid: string) =>
      `${explicitHost}/nft_wallet/assets/${guid}/mint_transfer`,
  },
  nft_orders: {
    create: (nftId: NFT["slug"]) => `${explicitHost}/nfts/${nftId}/orders`,
    update: (id: NFTOrder["id"]) => `${explicitHost}/nft_orders/${id}`,
  },
  rewards: {
    create: (projectId: Project["id"]) =>
      `${host}/projects/${projectId}/rewards`,
    update: (id: Reward["id"]) => `${host}/projects/rewards/${id}`,
  },
  teamMembers: {
    create: (projectId: Project["id"]) =>
      `${host}/projects/${projectId}/team_members`,
    update: (id: ProjectMember["id"]) => `${host}/team_members/${id}`,
  },
  artistWorks: {
    create: (projectId: Project["id"]) =>
      `${host}/projects/${projectId}/artist_works`,
    update: (id: ArtistWork["id"]) => `${host}/projects/artist_works/${id}`,
  },
  callToAction: {
    create: (projectId: Project["id"]) => `${host}/projects/${projectId}/cta`,
    update: (id: CallToAction["id"]) => `${host}/projects/cta/${id}`,
  },

  // editor v1 related
  website_versions: {
    patch: (slug: string) => `${host}/websites/versions/${slug}`,
  },
  sections: {
    patch: (id: number) => `${host}/websites/sections/${id}`,
  },
  renderers: {
    patch: (id: number) => `${host}/websites/section_renderers/${id}`,
  },
  currentUser: {
    // Test
    get: () => `${process.env.NEXT_PUBLIC_FRONTEND_API_HOST}/api/user/me`,
  },
};

export default api;
