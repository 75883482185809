import { AnyAction } from "@/store/index";
import { mergeEntities, setColorScheme } from "@/features/entities";
import api from "@/configs/api";
import { patch } from "@/features/requests";
import { Entities } from "@/types/index";
import { Town } from "@/types/api";

export const save = () => {
  return (dispatch: any, getState: any) => {
    const current = getState().entities;
    const id = current.versionId;
    dispatch({ type: "drafts/save", id, draft: current });
    dispatch({ type: "sectionIds/set", sectionIds: current.sectionIds });
  };
};

export const setIsEdited = (isEdited: boolean) => {
  return (dispatch: any, getState: any) => {
    const current = getState().entities;
    const id = current.versionId;
    dispatch({ type: "drafts/setIsEdited", id, isEdited });
  };
};

export const publish = () => {
  return async (dispatch: any, getState: any) => {
    const id = getState().entities.versionId;
    await patch(api.website_versions.patch(id), {
      is_live: true,
    })(dispatch, getState);
  };
};

export const update = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const id = getState().entities.versionId;
      const draft = getState().drafts[id];
      // update color_scheme
      await patch(api.website_versions.patch(id), {
        color_scheme: draft.entities.colorScheme,
      })(dispatch, getState);

      // update first secion propeties.background_image_url
      const firstSection =
        draft.entities.sections[draft.entities.sectionIds[0]];
      const background = firstSection.properties?.background_image_url;

      let ok = [];
      const response = await patch(api.sections.patch(firstSection.id), {
        properties: { background_image_url: background },
      })(dispatch, getState);
      ok.push(response.ok);

      // update all renderers with new content
      const renderers = Object.values(draft.entities.renderers);

      const promises = renderers.map((renderer: any) => {
        if (renderer.type === "froala" || renderer.type === "achievement") {
          return patch(api.renderers.patch(renderer.id), {
            content: renderer.content,
          })(dispatch, getState);
        }
      });

      // some promise is undefined, since it only updates froala and achievement
      const result = await Promise.all(promises.filter(Boolean));
      ok.push(...result.map((r: any) => r.ok));
      const allOk = ok.every((r: any) => r);

      if (allOk) {
        dispatch({ type: "drafts/discard", id });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const discard = () => {
  return (dispatch: any, getState: any) => {
    const confirmed = confirm("Are you sure you want to discard this draft?");
    if (confirmed) {
      const id = getState().entities.versionId;
      dispatch({ type: "drafts/discard", id });
      window.location.reload();
    }
  };
};

export const resume = (id: string) => {
  return (dispatch: any, getState: any) => {
    const saved = getState().drafts[id];
    dispatch(mergeEntities(saved.entities));
    setColorScheme(saved.entities.colorScheme)(dispatch, getState);
    dispatch({ type: "sectionIds/set", sectionIds: saved.entities.sectionIds });
  };
};

const initialState = {
  // [version_id]: {
  //   entities: {
  //     sections: {},
  //     renderers: {},
  //     sectionIds: [],
  //     colorScheme: 'blue',
  //   },
  // },
};

type State = {
  [version_id: string]: {
    entities: Entities;
    isEdited?: boolean;
  };
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case "drafts/save": {
      const { id, draft } = action;
      return {
        ...state,
        [id]: {
          entities: draft,
        },
      };
    }

    case "drafts/setIsEdited": {
      const { id, isEdited } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          isEdited,
        },
      };
    }

    case "drafts/discard": {
      const { id } = action;
      return {
        ...state,
        [id]: undefined,
      };
    }
    default: {
      return state;
    }
  }
};
