import { useTheme as muiUseTheme } from "@mui/material/styles";
import { Theme } from "@/types/index";

export const useTheme = () => {
  const theme = muiUseTheme() as Theme;

  return theme;
};

const otPrimary = {
  main: "#3988FF", // OT blue
  light: "#F3F7FF", // light blue
  medium: "#DDE9FF",
  contrastText: "#FFFFFF", // white
};

// template theme used to createTheme in edit, index etc.
export const baseTheme = {
  // base palette maybe overriden by the theme of a project
  palette: {
    primary: otPrimary,
    error: {
      main: "#FF1C52",
    },
  },
  typography: {
    // not using font family mechanism from mui
    // use className={noto.className} kind of annotation with next/font/google
    fontFamily: "inherit",
    h1: { fontSize: "48px", fontWeight: 700 },
    h2: { fontSize: "32px", fontWeight: 700 },
    h3: { fontSize: "24px", fontWeight: 700 },
    body1: { fontSize: "18px", fontWeight: 400 },
    body2: { fontSize: "16px", fontWeight: 400 },
  },
  ot: {
    palette: {
      primary: otPrimary,
    },
  },
};

export { ThemeProvider, createTheme } from "@mui/material/styles";
