export const colors = {
  grey100: "#F5F5F5", // disabled color
  grey500: "#C4C4C4",
  roadmapGrey: "#D9D9D9",
  default: "#333333",
  yellow: "#FFEB6C",
  rectangleGrey: "#EEEEEE",
  lightOrange: "#FFF1DD",
  deepBlue: "#364F80",
  white: "#FFFFFF",
  primaryLight: "#F3F7FF",
  primary: "#3988FF",
  backgroundGrey: "#F1F5FB",
  orange: "#FFA877",
  green: "#85F4B2",
  lightGreen: "#D0FEE8",
  blueBlack: "#08082D",
  twitter: "#1DA1F2",
  discord: "#7289DA",
  instagram: "#FF0069",
};
