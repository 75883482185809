import { AppDispatch } from "@/store";

type Toast = {
  message: string;
  type: "success" | "error";
};

type State = {
  toast: Toast | null;
};

type SetToastAction = {
  type: "toasts/set";
  payload: Toast;
};

type Action = SetToastAction;

export const setToast = (toast: Toast) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: "toasts/set", payload: toast });
  };
};

const initialState: State = {
  toast: null,
};

export const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case "toasts/set":
      return {
        ...state,
        toast: action.payload,
      };
    default:
      return state;
  }
};
